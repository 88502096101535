import React from "react";
import "./styles/Endorsements.css";

function Endorsements() {
  return (
    <div
      className="wp-block-columns is-layout-flex wp-container-core-columns-layout-33 wp-block-columns-is-layout-flex endorsements endorsements-section"
      id="endorsements"
    >
      <div
        className="wp-block-column is-layout-flow wp-block-column-is-layout-flow endorsements-section"
        style={{ flexBasis: "100%" }}
      >
        <div
          className="wp-block-group alignfull has-foreground-color has-primary-background-color has-text-color has-background has-global-padding is-layout-constrained wp-container-core-group-layout-43 wp-block-group-is-layout-constrained endorsements-section"
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            paddingTop: "var(--wp--preset--spacing--80)",
            paddingRight: "var(--wp--preset--spacing--50)",
            paddingBottom: "var(--wp--preset--spacing--80)",
            paddingLeft: "var(--wp--preset--spacing--50)",
          }}
        >
          <h2 className="wp-block-heading alignwide has-background-color has-text-color has-large-font-size endorsements-section-header">
            What people are saying
          </h2>

          <div className="wp-block-columns alignwide is-layout-flex wp-container-core-columns-layout-31 wp-block-columns-is-layout-flex">
            <div className="wp-block-column is-layout-flow wp-container-core-column-layout-40 wp-block-column-is-layout-flow">
              <p className="has-background-color has-text-color has-link-color wp-elements-748c6fdb72b926c49d219f0d66ab87b2 endorsements-section-text">
                “David is an excellent team member. Working as a part of my
                Front End Engineering team, David has proved to be adaptable,
                dedicated, dependable, with a growing confidence, and expending
                skill set. Through the years that I have worked with him so far,
                he has taken on tasks that vary on the difficulty spectrum and
                solved them all. David is frequently looking for the best way to
                solve a problem and delivering on time and within estimates.
                David was flexible enough to work with an EOL version of Angular
                and new react frameworks at the same building each in different
                simultaneous projects. His time and attention management is
                excellent,. More recently he has begun leading milestone
                demonstrations intended for management and assisting peers
                coming into the codebases.. David has always been a strong
                performer, and if given the chance in the future I would
                certainly recommend his hire.”
              </p>

              <a
                href="https://www.linkedin.com/in/dtrwos/"
                className="has-vivid-red-color has-text-color has-link-color wp-elements-47c4abfa708ff59b18e5640ba4837fce endorsements-section-accent no-underline"
              >
                — <strong>Chris Drottar</strong>
              </a>
            </div>

            <div className="wp-block-column is-layout-flow wp-container-core-column-layout-41 wp-block-column-is-layout-flow">
              <p className="has-background-color has-text-color has-link-color wp-elements-ce4b1369c8d34991e16b8ca954e2cf20 endorsements-section-text">
                "Working with David has been a great experience. His technical
                skills and creativity made him a valuable part of our team. He
                effectively translated front-end requirements into streamlined
                systems and enhanced dynamic forms using React, Angular, and
                CSS. I was especially impressed by his work on the HOP UI Kit,
                which showcased his commitment to both quality and innovation.
                David also communicated effectively with the product team,
                consistently reviewing designs, identifying gaps, and proposing
                alternative technical solutions to help define use cases in more
                detail. He was always ready to help any team member for
                onboarding or for new project work, making him a great team
                player. His strong teamwork and collaboration helped make our
                project a success."
              </p>

              <a
                href="https://www.linkedin.com/in/ashwini-bauskar/"
                className="has-vivid-red-color has-text-color has-link-color endorsements-section-accent no-underline"
              >
                — <strong>Ashwini Bauskar</strong>
              </a>
            </div>
          </div>

          <div className="wp-block-columns alignwide is-layout-flex wp-container-core-columns-layout-31 wp-block-columns-is-layout-flex">
            <div className="wp-block-column is-layout-flow wp-container-core-column-layout-40 wp-block-column-is-layout-flow">
              <p className="has-background-color has-text-color has-link-color wp-elements-748c6fdb72b926c49d219f0d66ab87b2 endorsements-section-text">
                “I had the honor of working with David at Internet Brands where
                he was instrumental in bringing our complex healthcare webapps
                to life. A solid, effective frontend dev with a crisp sense for
                building the right solutions at the right time, he works
                efficiently and autonomously. Any problem we could think of, I
                was confident that David would be able to find a solution. While
                his skills in frontend technologies like React and Angular are
                great, what makes him a standout teammate is his ability to
                break down complex problems into manageable pieces and his
                attention to detail.”
              </p>

              <a
                href="https://www.linkedin.com/in/deepikachaudhari/"
                className="has-vivid-red-color has-text-color has-link-color wp-elements-47c4abfa708ff59b18e5640ba4837fce endorsements-section-accent no-underline"
              >
                — <strong>Deepika Chaudhari</strong>
              </a>
            </div>

            <div className="wp-block-column is-layout-flow wp-container-core-column-layout-41 wp-block-column-is-layout-flow">
              <p className="has-background-color has-text-color has-link-color wp-elements-ce4b1369c8d34991e16b8ca954e2cf20 endorsements-section-text">
                “My team was primarily backend developers, but we would
                regularly borrow David in order to build react bundles for our
                projects. David was a highly skilled front end developer, who
                was a pleasure to work with. We could give him a design in figma
                or any other tool and he could create a pixel perfect
                implementation. If he had any concerns or questions, he would
                raise them immediately and proactively. If I had the
                opportunity, I would absolutely work with David again.”
              </p>

              <a
                href="https://www.linkedin.com/in/terencekulik/"
                className="has-vivid-red-color has-text-color has-link-color endorsements-section-accent no-underline"
              >
                — <strong>Terence Kulik</strong>
              </a>
            </div>
          </div>

          <div className="wp-block-columns alignwide is-layout-flex wp-container-core-columns-layout-31 wp-block-columns-is-layout-flex">
            <div className="wp-block-column is-layout-flow wp-container-core-column-layout-40 wp-block-column-is-layout-flow">
              <p className="has-background-color has-text-color has-link-color wp-elements-748c6fdb72b926c49d219f0d66ab87b2 endorsements-section-text">
                “David has collaborated closely with all of the front-end
                developers on my team over the past few years. He consistently
                demonstrated meticulous attention to detail, a clear design
                vision, and an ability to identify gaps in the process,
                proactively communicating them to the product team. I was highly
                impressed with his work and would confidently recommend him
              </p>

              <a
                href="https://www.linkedin.com/in/zohrehmalek/"
                className="has-vivid-red-color has-text-color has-link-color wp-elements-47c4abfa708ff59b18e5640ba4837fce endorsements-section-accent no-underline"
              >
                - <strong>Zohreh Malek</strong>
              </a>
            </div>

            <div className="wp-block-column is-layout-flow wp-container-core-column-layout-41 wp-block-column-is-layout-flow">
              <p className="has-background-color has-text-color has-link-color wp-elements-ce4b1369c8d34991e16b8ca954e2cf20 endorsements-section-text">
                “David plays an important roles in our frontend development
                team, he has shown a great progress from an associate to a well
                establish frontend developer. He is quick witted and passionate
                in his work, a very good communicator to make sure he can push
                his task forward.”
              </p>

              <a
                href="https://www.linkedin.com/in/budichandra/"
                className="has-vivid-red-color has-text-color has-link-color endorsements-section-accent no-underline"
              >
                — <strong>Budi Chandra</strong>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Endorsements;
