import React from "react";
import "./styles/Footer.css";

function Footer() {
  return (
    <a href="#top" className="footer footer-section">
      <div className="footer__text">
        <i className="fa fa-arrow-up footer-section-text"></i>
      </div>
    </a>
  );
}

export default Footer;
